@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Variables

// Bootstrap
@import 'https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css';
@import '~datatables/media/css/jquery.dataTables.min.css';
@import '~datatables.net-bs5/css/dataTables.bootstrap5.min.css';
@import '~select2/dist/css/select2.css';
@import 'node_modules/select2-bootstrap-5-theme/src/select2-bootstrap-5-theme';
@import 'bootstrap-daterangepicker';
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker3.css';

#datepicker {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

div.dataTables_processing > div:last-child > div {
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background: rgba(2, 117, 216, 0.9);
}

.bg-primary {
    background-color: #09729f !important;
}

.navbar-brand {
    color: #ffffff;
}

.bg-blue {
    background-color: #446084;

    .nav-link {
        color: #ffffff;
    }
}

.card-body {
    background-color: #eaf1fb;
}

.table_content {
    table.dataTable {
        border-spacing: 0 10px;
    }

    tbody tr td:first-child {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
    }

    .table > tbody > tr > td {
        vertical-align: middle;
    }

    .table > tbody > * > * {
        background-color: #fff;
    }
    .table td,
    .table th,
    .table thead th {
        border: none;
    }
    td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

table.dataTable.no-footer {
    border-bottom: none;
}

table.dataTable tbody th,
table.dataTable tbody td {
    padding: 1rem;
}
